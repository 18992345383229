import { useState } from 'react';
import { ChevronDown } from 'components/SharedIcons/ChevronDown';
import './selectCard.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

const SelectCard = ({ options, handleClick }: SelectCardDropdownProps) => {
  const { hero } = usewpdataSelectors();
  const [selectedOption, setSelectedOption] = useState(
    options.find((optionItem) => optionItem.default_option === true) || options[0]
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const mappedOptions = options.map((optionItem) => ({
    id: optionItem.id,
    label: optionItem.tr_title,
    ...optionItem,
  }));

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    handleClick({
      id: option.id,
      sku: option.sku,
      label: option.tr_title,
      price: option.price,
      old_price: option.old_price,
      konnektive_id: option.konnektive_id,
    });
  };

  return (
    <div className="selectCard">
      <div className="select__input" onClick={() => setDropdownOpen(!dropdownOpen)}>
        {selectedOption.label || selectedOption.tr_title}
        <ChevronDown className={dropdownOpen ? 'rotate-up' : ''} fill={hero?.styles?.textColorPrimary || '#45050C'} />
      </div>
      {dropdownOpen && (
        <div
          className="selectCard-dropdown"
          style={{
            position: 'absolute',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '8px',
            maxHeight: '200px',
            overflowY: 'auto',
            marginTop: '8px',
            width: '100%',
            zIndex: '10',
          }}
        >
          {mappedOptions.map((option) => (
            <div
              key={option.id}
              onClick={() => handleOptionSelect(option)}
              className="selectCard-option"
              style={{
                padding: '16px',
                backgroundColor: selectedOption.id === option.id ? '#f0f0f0' : '#fff',
                cursor: 'pointer',
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

interface SelectCardDropdownProps {
  handleClick: (
    id: string,
    sku: string,
    label: string,
    price: number,
    old_price: number,
    konnektive_id: string
  ) => void;
  options: {
    id: number;
    label: string;
    display_name: string;
    tr_additional_texts: string;
    price: number;
    old_price: number;
    status?: string;
    image: string;
    default_option: boolean;
    tr_title: string;
  }[];
}

export default SelectCard;
