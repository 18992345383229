import React from 'react';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import { useCartSelectors } from 'lib/cartState/selectors';
import VariantSelect from './VariantSelect';

type VariantPickerProps = {
  numberOfItems: number;
  id?: string | number;
  defaultVariant: {
    color?: string;
    colorLabel?: string;
    colorImage?: string | boolean;
    size?: string;
    sizeLabel?: string;
    id?: string;
  };
};

const VariantPicker = ({ numberOfItems, defaultVariant }: VariantPickerProps) => {
  const { ProductColorVariants, ProductSizeVariants } = useproductsdataSelectors();
  const { cartProducts } = useCartSelectors();

  return (
    <section className="variant-container">
      <div className="variant-container__wrapper">
        <div className="variant-container__form">
          <div className="variant-container__form--wrapper" style={{ width: '100%', display: 'flex' }}>
            <div className="variant-column__int">
              {[...Array(Number(numberOfItems))].map((_, i) => (
                <span key={i}>{i + 1}.</span>
              ))}
            </div>
            <div className="variant-column" style={{ marginRight: '8px' }}>
              {ProductColorVariants &&
                [...Array(Number(numberOfItems))].map((_, i) => (
                  <VariantSelect
                    key={i}
                    data={ProductColorVariants}
                    type="color"
                    id={i}
                    size={numberOfItems}
                    defaultVariant={cartProducts?.mainProduct.variants[i] || defaultVariant}
                  />
                ))}
            </div>
            <div className="variant-column">
              {ProductSizeVariants &&
                [...Array(Number(numberOfItems))].map((_, i) => (
                  <VariantSelect
                    key={i}
                    data={ProductSizeVariants}
                    id={i}
                    size={numberOfItems}
                    type="size"
                    defaultVariant={cartProducts?.mainProduct.variants[i] || defaultVariant}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VariantPicker;
