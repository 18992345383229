import React from 'react';
import s from './LoadingOverlay.module.scss';
import { useCartSelectors } from 'lib/cartState/selectors';

const LoadingOverlay = () => {
  const { isLoading } = useCartSelectors();

  if (isLoading) {
    return <div className={s.loadingOverlay}></div>;
  }
};

export default LoadingOverlay;
