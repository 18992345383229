import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import s from './styles/heroTitles.module.scss';
import RatingRow from './RatingRow';
import { decode } from 'html-entities';

const HeroTitles = () => {
  const { hero, Theme } = usewpdataSelectors();

  return (
    <div className={s.heroTitles}>
      {hero.show_socials && <RatingRow />}
      <h1
        style={{ color: hero.styles.textColorPrimary }}
        data-theme={Theme.heading}
        dangerouslySetInnerHTML={{ __html: decode(hero.title) }}
      ></h1>
      <h4 style={{ color: hero.styles.textColorPrimary }}>{hero.subtitle}</h4>
    </div>
  );
};

export default HeroTitles;
