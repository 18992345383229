import React, { Suspense, useEffect, useState } from 'react';
import { useCartSelectors } from 'lib/cartState/selectors';

// Lazy load the Cart component
const Cart = React.lazy(() => import('./Cart'));

const LazyCart = () => {
  const [shouldLoadCart, setShouldLoadCart] = useState(false);
  const { isCartOpen } = useCartSelectors();

  useEffect(() => {
    if (isCartOpen && !shouldLoadCart) {
      setShouldLoadCart(true);
    }
  }, [isCartOpen, shouldLoadCart]);

  return (
    <div>
      {shouldLoadCart && (
        <Suspense fallback={<div>Loading Cart...</div>}>
          <Cart />
        </Suspense>
      )}
    </div>
  );
};

export default LazyCart;
