import { wpData } from './wpDataType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const root: any = window?.post_template;
const clean: any = window.clean_settings;
const settings: any = window.general_settings;

const initialState: wpData = {
  settings: {
    logo: settings?.logo,
    logoWhite: settings?.logo_white,
    brandName: settings?.brand_name,
    formattedString: window.formattedString,
    phone: settings?.phone_number,
    email: settings?.support_email,
    link: window?.cta_link,
    root: window.template_root,
    bundleMode: window.productData.show_bundle_design,
    hideAdd: window.post_template.product_page_v6_cart.product_page_v6_cart_add_hide,
    disableCart: window.post_template.product_page_v6_cart.product_page_v6_cart_func_disable,
    disablePrices: window.post_template.product_page_v6_hero.product_page_v6_hero_showPrices,
    showPriceInCTA: window.post_template.product_page_v6_hero.product_page_v6_hero_showCTAprice,
    showQtyIncrementer: window.post_template.product_page_v6_hero.product_page_v6_hero_showIncrementer,
    productCardDescriptionType: window.post_template.product_page_v6_hero.product_page_v6_hero_descriptiontype,
    isHeroVisible: true,
  },
  cleanSettings: {
    cleanMode: clean,
  },
  theme: {
    button: root?.product_page_v6_components?.product_page_v6_button_theme,
    heading: root?.product_page_v6_components?.product_page_v6_headings_theme,
    headingColor: root?.product_page_v6_components?.product_page_v6_title_text_color,
    subheadingColor: root?.product_page_v6_components?.product_page_v6_subtitle_text_color,
    customButton: {
      background: root?.product_page_v6_components?.product_page_v6_button_background,
      backgroudHover: root?.product_page_v6_components?.product_page_v6_button_background_hover,
      borderColor: root?.product_page_v6_components?.product_page_v6_button_background_border,
      textColor: root?.product_page_v6_components?.product_page_v6_button_text_color,
    },
  },

  layout: {
    hero: {
      display: root.product_page_v6_hero?.product_page_v6_hero_show,
      layout: root.product_page_v6_hero?.product_page_v6_hero_layout || '',
    },
    featuredIn: {
      display: root.product_page_v6_slider?.product_page_v6_slider_display,
      layout: '',
    },
    videoTestimonials: {
      display: root?.product_page_v6_reviews?.product_page_v6_reviews_show,
      order: 5,
    },
    middleBar: {
      display: root?.product_page_v6_middleBar?.product_page_v6_middleBar_show,
    },
    videoTestimonialsImages: {
      display: root?.product_page_v6_reviews?.product_page_v6_reviewSlider_show,
    },
    baComparison: {
      display: root?.product_page_v6_baComparison?.product_page_v6_baComparison_show,
      order: root?.product_page_v6_baComparison?.product_page_v6_baComparison_order,
    },
    features: {
      display: root?.product_page_v6_featureslist?.product_page_v6_featuresList_show,
      layout: '',
    },
    setup: {
      display: root?.product_page_v6_setup?.product_page_v6_setup_show,
      layout: '',
    },
    competitorsComparison: {
      display: root?.product_page_v6_competitors?.product_page_v6_competitors_show,
      order: root?.product_page_v6_competitors?.product_page_v6_competitors_order,
    },
    products: {
      display: root?.product_page_v6_products?.product_page_v6_products_show,
      layout: '',
    },
    testimonialsSlider: {
      display: root?.product_page_v6_testimonials?.product_page_v6_testimonials_show,
      layout: '',
    },

    ribbon: {
      display: root?.product_page_v6_benefitsRibbon?.product_page_v6_benefitsRibbon_showSection,
      layout: '',
    },
    faq: {
      display: root?.product_page_v6_faq?.product_page_v6_faq_display,
      layout: '',
    },
    awards: {
      display: root?.product_page_v6_awardCard?.product_page_v6_award_show,
      layout: '',
    },
    expert: {
      display: root?.product_page_v6_expert?.product_page_v6_expert_show,
      layout: '',
    },
    reviews: {
      display: root?.product_page_v6_reviewsList?.product_page_v6_reviewsList_show,
      layout: root?.product_page_v6_reviewsList?.product_page_v6_reviewsList_layout || '',
    },
    floater: {
      display: root?.product_page_v6_floater?.product_page_v6_floater_show,
      layout: '',
    },
  },
  header: {
    headerNavigationLinks: root?.product_page_v6_navigation?.product_page_v6_navigation_links,
    headerNavRightButtonText: root?.product_page_v6_navigation?.tr_product_page_v6_navigation_cta,
    styles: {
      background: root?.product_page_v6_navigation?.product_page_v6_navigation_background,
      textColor: root?.product_page_v6_navigation?.product_page_v6_navigation_linkColor,
      textHoverColor: root?.product_page_v6_navigation?.product_page_v6_navigation_linkHover,
      borderColor: root?.product_page_v6_navigation?.product_page_v6_navigation_borderColor,
    },
  },
  discountBar: {
    text: root.product_page_v6_discountBar?.tr_product_page_v6_discountBar_text,
    styles: {
      background: root.product_page_v6_discountBar?.product_page_v6_discountBar_background,
      textColor: root.product_page_v6_discountBar?.product_page_v6_discountBar_color,
    },
  },
  middleBar: {
    list: root?.product_page_v6_middleBar?.product_page_v6_middleBar_list,
    styles: {
      background: root?.product_page_v6_middleBar?.product_page_v6_middleBar_background,
      textColor: root?.product_page_v6_middleBar?.product_page_v6_middleBar_primaryColor,
    },
  },
  hero: {
    title: root?.product_page_v6_hero?.tr_product_page_v6_hero_heading,
    subtitle: root?.product_page_v6_hero?.tr_product_page_v6_hero_subheading,
    hero_image: root?.product_page_v6_hero?.product_page_v6_hero_staticImage,
    show_socials: root?.product_page_v6_hero?.product_page_v6_hero_showSocials,
    show_stockLeft: root?.product_page_v6_hero?.product_page_v6_hero_showStockLeft,
    divider_text: root?.product_page_v6_hero?.tr_product_page_v6_hero_dividerText,
    bought_this: root?.product_page_v6_hero?.tr_product_page_v6_hero_bought_this,
    instaBubbles: root?.product_page_v6_hero?.product_page_v6_hero_bubbles,
    chart_link: root?.product_page_v6_hero?.product_page_v6_hero_chartLink,
    chart_label: root?.product_page_v6_hero?.tr_product_page_v6_hero_chartLabel,
    free_label: root?.product_page_v6_hero?.tr_product_page_v6_hero_freeLabel,
    left_label: root?.product_page_v6_hero?.tr_product_page_v6_hero_leftLabel,
    availability_label: root?.product_page_v6_hero?.tr_product_page_v6_hero_limitedAvailability,
    each_label: root?.product_page_v6_hero?.tr_product_page_v6_hero_eachLabel,
    star_rating: {
      rating: root?.product_page_v6_hero?.product_page_v6_hero_score,
      rating_text: 'Excellent',
      ratings_count: '158',
      ratings_count_text: root?.product_page_v6_hero?.tr_product_page_v6_hero_reviewsLabel,
      ratings_count_text_mobile: root?.product_page_v6_hero?.tr_product_page_v6_hero_reviewsLabel_mobile,
    },
    testimonials: root?.product_page_v6_hero?.product_page_v6_hero_testimonials,
    gallery: root?.product_page_v6_hero?.product_page_v6_hero_imageGallery,
    faqList: root?.product_page_v6_hero?.product_page_v6_hero_faq,
    shopWithin: root?.product_page_v6_hero?.tr_product_page_v6_hero_orderWithin,
    shopWihinTwo: root?.product_page_v6_hero?.tr_product_page_v6_hero_orderWithin_two,
    checklist: root?.product_page_v6_hero?.product_page_v6_hero_checklist,
    rating_avatars: [
      'http://127.0.0.1:5173/temp_images/hero/rating_row/1.jpg',
      'http://127.0.0.1:5173/temp_images/hero/rating_row/2.jpg',
      'http://127.0.0.1:5173/temp_images/hero/rating_row/3.jpg',
      'http://127.0.0.1:5173/temp_images/hero/rating_row/4.jpg',
      'http://127.0.0.1:5173/temp_images/hero/rating_row/5.jpg',
    ],
    benefits: root?.product_page_v6_hero?.product_page_v6_hero_features,
    ctaButtonText: root?.product_page_v6_hero?.tr_product_page_v6_hero_ctaLabel,
    styles: {
      background: root?.product_page_v6_hero?.product_page_v6_hero_backgroundColor,
      textColorPrimary: root?.product_page_v6_hero?.product_page_v6_hero_textPrimary,
      textColorSecondary: root?.product_page_v6_hero?.product_page_v6_hero_textSecondary,
      accentColor: root?.product_page_v6_hero?.product_page_V6_hero_text_accent,
      bestSellerColor: root?.product_page_v6_hero?.product_page_v6_hero_background_best_seller,
      bestValueColor: root?.product_page_v6_hero?.product_page_v6_hero_background_best_value,
    },
  },
  logoSlider: {
    staticImage: root?.product_page_v6_slider?.product_page_v6_slides_image,
    repeater: root?.product_page_v6_slider?.product_page_v6_slider_slides,
    styles: {
      background: root?.product_page_v6_slider.product_page_v6_slider_background,
    },
  },
  features: {
    show: root?.product_page_v6_featureslist?.product_page_v6_featuresList_show,
    title: root?.product_page_v6_featureslist?.tr_product_page_v6_featureslist_heading,
    subtitle: root?.product_page_v6_featureslist?.tr_product_page_v6_featureslist_subheading,
    score: root?.product_page_v6_featureslist?.product_page_v6_featuresList_score,
    starsValue: root?.product_page_v6_featureslist?.product_page_v6_featuresList_stars,
    list: root?.product_page_v6_featureslist?.product_page_v6_featureslist_repeater,
    styles: {
      background: root?.product_page_v6_featureslist?.product_page_v6_featuresList_background,
      textColorPrimary: root?.product_page_v6_featureslist?.product_page_v6_featuresList_primaryColor,
      textColorSecondary: root?.product_page_v6_featureslist?.product_page_v6_featuresList_secondaryColor,
      checkboxColor: root?.product_page_v6_featureslist?.product_page_v6_featuresList_colorAccent,
    },
  },
  setup: {
    title: root?.product_page_v6_setup?.tr_product_page_v6_setup_title,
    subtitle: root?.product_page_v6_setup?.tr_product_page_v6_setup_subtitle,
    show: root?.product_page_v6_setup?.product_page_v6_setup_show,
    list: root?.product_page_v6_setup?.product_page_v6_setup_repeater,
    styles: {
      background: root?.product_page_v6_setup?.product_page_v6_setup_sectionBackground,
      textColorPrimary: root?.product_page_v6_setup?.product_page_v6_setup_textPrimary,
      textColorSecondary: root?.product_page_v6_setup?.product_page_v6_setup_textSecondary,
    },
  },
  FloatingCTA: {
    floaterImage: root?.product_page_v6_floater?.product_page_v6_floater_image,
    starsImage: root?.product_page_v6_floater?.product_page_v6_floater_stars,
    productScore: root?.product_page_v6_floater?.product_page_v6_floater_score,
    ctaLabel: root?.product_page_v6_floater?.tr_product_page_v6_floater_cta,
    timerLabel: root?.product_page_v6_floater?.tr_product_page_v6_floater_timer,
    floaterHeading: root?.product_page_v6_floater?.tr_product_page_v6_floater_heading,
    verbalScore: root?.product_page_v6_floater?.tr_product_page_v6_floater_scoreVerbal,
    styles: {
      background: root?.product_page_v6_floater?.product_page_v6_floater_gradient,
      textColorPrimary: root?.product_page_v6_floater?.product_page_v6_floater_textColor,
      textColorSecondary: root?.product_page_v6_floater?.product_page_v6_floater_textColorSecondary,
    },
  },
  competitorsComparison: {
    heading: root?.product_page_v6_competitors?.tr_product_page_v6_competitors_heading,
    tableHeading: root?.product_page_v6_competitors?.tr_product_page_v6_competitors_tableHeading,
    ctaLabel: root?.product_page_v6_competitors?.tr_product_page_v6_competitors_cta,
    moneyBackLabel: root?.product_page_v6_competitors?.tr_product_page_v6_competitors_moneyBack,
    otherProduct: root?.product_page_v6_competitors?.tr_product_page_v6_competitors_otherLabel,
    productImage: root?.product_page_v6_competitors?.product_page_v6_competitors_image,
    competitorsImage: root?.product_page_v6_competitors?.product_page_v6_competitors_otherImage,
    repeater: root?.product_page_v6_competitors?.product_page_v6_competitors_table,
    styles: {
      background: root?.product_page_v6_competitors?.product_page_v6_competitors_background,
      evenTable: root?.product_page_v6_competitors?.product_page_v6_competitors_even,
      oddTable: root?.product_page_v6_competitors?.product_page_v6_competitors_odd,
      textColorPrimary: root?.product_page_v6_competitors?.product_page_v6_competitors_primaryColor,
      tetColorSecondary: root?.product_page_v6_competitors?.product_page_v6_competitors_secondaryColor,
      oddTableOur: root?.product_page_v6_competitors?.product_page_v6_competitors_oddOur,
      evenTableOur: root?.product_page_v6_competitors?.product_page_v6_competitors_evenOur,
    },
  },
  testimonialsSwiper: {
    title: root?.product_page_v6_testimonials?.tr_product_page_v6_testimonials_heading,
    subtitle: root?.product_page_v6_testimonials?.tr_product_page_v6_testimonials_subheading,
    ratedLabel: root?.product_page_v6_testimonials?.tr_product_page_v6_ratedLabel,
    list: root?.product_page_v6_testimonials?.product_page_v6_testimonials_repeater,
    styles: {
      background: root?.product_page_v6_testimonials?.product_page_v6_testimonials_background,
      textColorPrimary: root?.product_page_v6_testimonials?.product_page_v6_testimonials_primary_color,
      textColorSecondary: root?.product_page_v6_testimonials?.product_page_v6_testimonials_secondary_color,
      textColorAccent: root?.product_page_v6_testimonials?.product_page_v6_testimonials_accent_color,
    },
  },
  cart: {
    dividerLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_divider_label || '',
    cartLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_cart_label || '',
    savingsLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_savings_label || '',
    subtotalLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_subtotal_label || '',
    timerLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_timer_label || '',
    bestSellerLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_bestSeller_label || '',
    ctaLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_cta_label || '',
    upgradeLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_upgrade_label || '',
    viewLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_view_label || '',
    addLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_add_label || '',
    freeShippingLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_shipping,
    freeShippingUnlockLabel: root?.product_page_v6_cart?.tr_product_page_v6_cart_freeShipping,
    freeShippingPrice: root?.product_page_v6_cart?.tr_product_page_v6_cart_shippingAmount,
    styles: {
      colorPrimary: root?.product_page_v6_cart?.tr_product_page_v6_colorPrimary || '',
      colorSecondary: root?.product_page_v6_cart?.tr_product_page_v6_colorSecondary || '',
      badgeColor: root?.product_page_v6_cart?.tr_product_page_v6_badgeColor || '',
      shippingBarColor: root?.product_page_v6_cart?.tr_product_page_v6_freeShippingBar,
      accentColor: root?.product_page_v6_cart?.tr_product_page_v6_accentColor,
    },
  },
  baComparison: {
    title: root?.product_page_v6_baComparison?.tr_product_page_v6_baComparison_heading,
    list: root?.product_page_v6_baComparison?.product_page_v6_baComparison_repeater,
    styles: {
      background: root?.product_page_v6_baComparison?.product_page_v6_baComparison_background,
      textColorPrimary: root?.product_page_v6_baComparison?.product_page_v6_baComparison_primaryColor,
      textColorSecondary: root?.product_page_v6_baComparison?.product_page_v6_baComparison_secondaryColor,
    },
  },
  videoTestimonials: {
    title: root?.product_page_v6_reviews?.tr_product_page_v6_reviews_heading,
    rating: 4.7,
    ratingText: root?.product_page_v6_reviews?.tr_product_page_v6_reviews_trusted,
    verifiedText: 'Verified',
    verifyIcon: 'http://127.0.0.1:5173/images/homepage/testimonialsSection/testimonialCard/verify.svg',
    testimonials: root?.product_page_v6_reviews?.product_page_v6_reviews_video_repeater,
    styles: {
      background: root?.product_page_v6_reviews?.product_page_v6_reviews_background,
    },
  },
  videoTestimonialsImages: {
    repeater: root?.product_page_v6_reviews?.product_page_v6_reviews_images,
  },
  productsSection: {
    title: root?.product_page_v6_products?.tr_product_page_v6_products_heading,
    reviewsText: 'Verified Reviews',
    products: root?.product_page_v6_products?.product_page_v6_products_sectionRepeater,
    styles: {
      background: root?.product_page_v6_products?.product_page_v6_products_background,
      backgroundAccent: root?.product_page_v6_products?.product_page_v6_products_backgroundAccent,
      backgroundBadge: root?.product_page_v6_products?.product_page_v6_products_backgroundDiscount,
      textColorPrimary: root?.product_page_v6_products?.product_page_v6_products_textPrimary,
      textColorSecondary: root?.product_page_v6_products?.product_page_v6_products_textSecondary,
      accentColor: root?.product_page_v6_products?.product_page_v6_products_textAccent,
      badgeColor: root?.product_page_v6_products?.product_page_v6_products_background,
    },
  },

  slidingTextBar: {
    repeater: root?.product_page_v6_benefitsRibbon?.product_page_v6_benefitsRibbon_repeater,
    styles: {
      background: root?.product_page_v6_benefitsRibbon?.product_page_v6_benefitsRibbon_background,
      textColor: root?.product_page_v6_benefitsRibbon?.product_page_v6_benefitsRibbon_textColor,
    },
  },
  FAQ: {
    sectionTitle: root?.product_page_v6_faq?.tr_product_page_v6_faq_title,
    items: root?.product_page_v6_faq?.product_page_v6_faq_list_repeater,
    styles: {
      background: root?.product_page_v6_faq?.product_page_v6_faq_background,
    },
  },

  awards: {
    rating: '',
    title: root?.product_page_v6_awardCard?.tr_product_page_v6_award_heading,
    subtitle: root?.product_page_v6_awardCard?.tr_product_page_v6_award_subheading,
    image: root?.product_page_v6_awardCard?.product_page_v6_award_productImage,
    awardImage: root?.product_page_v6_awardCard?.product_page_v6_award_badge,
  },
  expertBlock: {
    title: root?.product_page_v6_expert?.tr_product_page_v6_expert_meetExpertLabel,
    subtitle: root?.product_page_v6_expert?.product_page_v6_expert_occupation,
    text: root?.product_page_v6_expert?.tr_product_page_v6_expert_recommendation,
    name: root?.product_page_v6_expert?.product_page_v6_expert_name,
    ourStory: root?.product_page_v6_expert?.tr_product_page_v6_expert_ourStory,
    avatarUrl: root?.product_page_v6_expert?.product_page_v6_expert_image,
    withLove: 'With Love,',
    signatureImgUrl: root?.product_page_v6_expert?.product_page_v6_expert_signature,
  },

  customerReviewsBlock: {
    title: root?.product_page_v6_reviewsList?.tr_product_page_v6_reviewsList_title,
    subtitle: root?.product_page_v6_reviewsList?.tr_product_page_v6_reviewsList_subtitle,
    reviews: root?.product_page_v6_reviewsList?.product_page_v6_reviewsList_repeater,
    showLessLabel: root?.product_page_v6_reviewsList?.tr_product_page_v6_reviewslist_loadLess,
    showMoreLabel: root?.product_page_v6_reviewsList?.tr_product_page_v6_reviewslist_readmore,
    verifiedLabel: root?.product_page_v6_reviewsList?.tr_product_page_v6_reviewsList_verified,
    helpfulLabel: root?.product_page_v6_reviewsList?.tr_product_page_v6_reviewsList_helpfulLabel,
    loadSlides: root?.product_page_v6_reviewsList?.product_page_v6_reviewsList_index,
    styles: {
      background: root?.product_page_v6_reviewsList?.product_page_v6_reviewsList_background,
      buttonColor: root?.product_page_v6_reviewsList?.product_page_v6_reviewsList_buttonColor,
      bubbleBackground: root?.product_page_v6_reviewsList?.product_page_v6_reviewsList_initialBubbleColor,
    },
  },
  statistics: {
    reviewsLabel: root?.product_page_v6_reviews_score.tr_product_page_v6_reviews_score_reviewsLabel,
    writeReviewLabel: root?.product_page_v6_reviews_score.tr_product_page_v6_reviews_score_writeReviewLabel,
    numberOfReviews: root?.product_page_v6_reviews_score.product_page_v6_reviews_score_number,
    reviewsAverage: root?.product_page_v6_reviews_score.product_page_v6_reviews_score_int,
    percentOfReviewers: root?.product_page_v6_reviews_score.tr_product_page_v6_reviews_score_percent,
    repeater: root?.product_page_v6_reviews_score.product_page_v6_reviews_score_qualities,
    styles: {
      barActive: root?.product_page_v6_reviews_score.product_page_v6_reviews_score_colorActive,
      barPassive: root?.product_page_v6_reviews_score.product_page_v6_reviews_score_colorPassive,
      textColorPrimary: root?.product_page_v6_reviews_score.product_page_v6_reviews_score_colorPassive,
      textColorSecondary: root?.product_page_v6_reviews_score.product_page_v6_reviews_score_textSecondary,
    },
  },
  footer: {
    newsletter: {
      title: root?.product_page_v6_footer?.tr_newsLetter_title,
      formInputPlaceholder: root?.product_page_v6_footer?.tr_input_placeholder,
      formSubmitText: root?.product_page_v6_footer?.tr_submit_label,
      textAfterSubscribeForm: root?.product_page_v6_footer?.tr_submit_afterLabel,
      rating: 4.7,
    },
    benefits: root?.product_page_v6_footer?.product_page_v6_footer_benefits,
    text: root?.product_page_v6_footer?.tr_footer_product_description,
    copyText: root?.product_page_v6_footer?.tr_footer_copyrights_text,
    customerCareLabel: root?.product_page_v6_footer?.tr_footer_customer_care_label,
    emailSupportLabel: root?.product_page_v6_footer?.tr_footer_email_support_label,
    liveChatLabel: root?.product_page_v6_footer?.tr_footer_chat_support_label,
    links: root?.product_page_v6_footer?.footer_link_columns,
    styles: {
      background: root?.product_page_v6_footer?.footer_section_background_color,
      primaryColor: root?.product_page_v6_footer?.footer_text_color,
      linkHoverColor: root?.product_page_v6_footer?.footer_link_text_color_hover,
    },
  },
};

const wpdataSlice = createSlice({
  name: 'wpdata',
  initialState,
  reducers: {},
});

export default wpdataSlice.reducer;
