import { useEffect, useState } from 'react';
import s from './QuantityCards.module.scss';
import { productType, variantObjectType, variantObject } from 'lib/productsState/productsTypes';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import { useCartActions } from 'lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';
import QuantityCard from './QuantityCard';
import SelectCard from './SelectCard';
import Divider from '../ContentBlock/Divider';
import Button from 'components/Button/CTA/Button';
import QuantityCardBundles from './QuantityCardBundles';
import QuantityIncrementer from '../qtyIncrementer/QuantityIncrementer';

const QuantityCards = ({ product }: { product: productType[] }) => {
  const { Settings } = usewpdataSelectors();
  const { hero } = usewpdataSelectors();
  const [price, setPrice] = useState<string>();
  const { cartProducts } = useCartSelectors();
  const { pickerUpsells, EnableColorVariants, EnableSizeVariants, ProductColorVariants, ProductSizeVariants } =
    useproductsdataSelectors();
  const {
    setSelectedProductAction,
    setDefaultVariantsAction,
    changeBundlePriceAction,
    changeBundleProduct,
    calculateTotalsAction,
    setProductsQuantityAction,
  } = useCartActions();

  useEffect(() => {
    calculateTotalsAction();
    setProductsQuantityAction();
  }, [cartProducts]);

  let defaultVariant: variantObjectType = {};

  if (EnableColorVariants && ProductColorVariants.length > 0) {
    const defaultColor = ProductColorVariants.find((item) => {
      return item.default_variant === true;
    });

    defaultVariant = {
      color: defaultColor?.value,
      colorLabel: defaultColor?.tr_label,
      colorImage: defaultColor?.variant_image,
    };
  }

  if (EnableSizeVariants && ProductSizeVariants.length > 0) {
    const defaultSize = ProductSizeVariants.find((item) => {
      return item.default_variant === true;
    });
    defaultVariant = {
      ...defaultVariant,
      size: defaultSize?.value,
      sizeLabel: defaultSize?.tr_label,
    };
  }

  defaultVariant = {
    ...defaultVariant,
    id: `${defaultVariant.size && defaultVariant.size}-${defaultVariant.color && defaultVariant.color}`,
  };

  useEffect(() => {
    const defaultProduct = product.find((item) => item.status.toLowerCase() === 'best seller');
    if (EnableColorVariants || EnableSizeVariants) {
      setSelectedProductAction(defaultProduct, defaultVariant);
    } else {
      setSelectedProductAction(defaultProduct);
    }
    pickerUpsells &&
      pickerUpsells.forEach((upsell) => {
        const default_option = upsell.products_list.find((item) => !!item.default_option === true);
        changeBundlePriceAction({
          id: default_option?.id,
          parent_id: upsell.id,
          sku: default_option?.sku,
          label: default_option?.tr_title,
          price: default_option?.price,
          old_price: default_option?.old_price,
          konnektive_id: default_option?.konnektive_id,
        });
      });
    if (
      (EnableColorVariants && ProductColorVariants.length > 0) ||
      (EnableSizeVariants && ProductSizeVariants.length > 0)
    ) {
      setDefaultVariantsAction(5, ProductColorVariants, ProductSizeVariants);
    }
  }, []);

  const handleClick = (id) => {
    const selectedProduct = product.filter((item) => {
      return item.id === id;
    });

    if (EnableColorVariants || EnableSizeVariants) {
      setSelectedProductAction(...selectedProduct, defaultVariant);
    } else {
      setSelectedProductAction(...selectedProduct);
    }
  };

  const handleUpsellClick = ({
    id,
    parent_id,
    sku,
    label,
    price,
    old_price,
    konnektive_id,
  }: {
    id: string;
    parent_id: string;
    sku: string;
    label: string;
    price: number;
    old_price: number;
    konnektive_id: string;
  }): void => {
    changeBundleProduct(sku, label, id, parent_id, price, old_price, konnektive_id);
  };

  useEffect(() => {
    if (Settings?.showPriceInCTA && cartProducts) {
      const mainProductPrice = Number(cartProducts?.mainProduct?.product?.price) || 0;
      const bundlesPrice =
        cartProducts?.mainProduct?.bundle?.reduce((acc, curr) => {
          return acc + (Number(curr.price) || 0);
        }, 0) || 0;

      const totalPrice = (
        (Number(mainProductPrice) + Number(bundlesPrice)) *
        Number(cartProducts.mainProduct.product.amount)
      ).toFixed(2);
      const formattedString = Settings?.formattedString;
      const price = formattedString.replace(/(0\.00|0,00|0)/, totalPrice.toString());
      setPrice(price);
    }
  }, [cartProducts?.mainProduct]);

  return (
    <section className={s.quantity}>
      <div className={s.quantity__cardList}>
        {product &&
          product.map((item: productType) => {
            const active = cartProducts?.mainProduct.product.id === item.id;
            if (item?.bundle_products?.length > 0) {
              return;
            }
            return (
              <QuantityCard
                colorVariants={EnableColorVariants}
                sizeVariants={EnableSizeVariants}
                defaultVariant={defaultVariant}
                active={active}
                id={item.id}
                key={item.id}
                amount={item.amount}
                status={item.status}
                display_name={item.display_name}
                price={item.price}
                image={item.image.sizes?.thumbnail}
                tr_additional_texts={item.tr_additional_texts}
                old_price={item.old_price}
                is_label_enabled={item.best_seller ?? false}
                handleClick={(id: string) => {
                  handleClick(id);
                }}
              />
            );
          })}
        {product &&
          product.map((item: productType) => {
            const active = cartProducts?.mainProduct.product.id === item.id;
            if (item?.bundle_products?.length > 0) {
              return (
                <QuantityCardBundles
                  active={active}
                  id={item.id}
                  key={item.id}
                  amount={item.amount}
                  status={item.status}
                  display_name={item.display_name}
                  price={item.bundle_products[0]?.bp_price}
                  image={item.image.sizes?.thumbnail}
                  tr_additional_texts={item.tr_additional_texts}
                  old_price={item.old_price}
                  is_label_enabled={item.best_seller ?? false}
                  handleClick={(id: string) => {
                    handleClick(id);
                  }}
                />
              );
            }
          })}
        {pickerUpsells &&
          pickerUpsells.map((upsell, index) => {
            if (upsell.upsell_layout === 'dropdown') {
              return (
                <div key={index} style={{ display: !Settings.disablePrices ? 'none' : 'block' }}>
                  <Divider text={upsell?.tr_divider_text} addMargin={true} />

                  <SelectCard
                    options={upsell?.products_list}
                    handleClick={(e) => {
                      handleUpsellClick({ parent_id: upsell?.id, ...e });
                    }}
                  />
                </div>
              );
            } else {
              return (
                <>
                  <Divider text={upsell?.tr_divider_text} />
                  {upsell?.products_list &&
                    upsell?.products_list.map((item, index) => {
                      const active = cartProducts?.mainProduct?.bundle.some((cart) => {
                        return cart.id === item.id;
                      });
                      return (
                        <QuantityCard
                          active={active}
                          id={item.id}
                          key={index}
                          amount={1}
                          display_name={item.tr_title}
                          price={item.price}
                          image={item.image}
                          old_price={item.old_price}
                          tr_additional_texts={item.tr_description}
                          defaultVariant={defaultVariant}
                          status={item.tr_status_label}
                          is_label_enabled={item.tr_status_label ?? false}
                          handleClick={(id) => {
                            handleUpsellClick({
                              parent_id: upsell.id,
                              id: id,
                              sku: item.sku,
                              label: item.tr_title,
                              price: item.price,
                              old_price: item.old_price,
                              konnektive_id: item.konnektive_id,
                            });
                          }}
                        />
                      );
                    })}
                </>
              );
            }
          })}
      </div>
      <div className={`${s.ctaWrapper} ${!!Settings.showQtyIncrementer && s.ctaWrapper__bundle}`}>
        {Settings?.showQtyIncrementer && <QuantityIncrementer />}
        <Button text={`${hero?.ctaButtonText}${price ? ` | ${price}` : ''}`} className={s.ctaButton} />
      </div>
    </section>
  );
};
export default QuantityCards;
