import { useState, useEffect, useRef } from 'react';
import s from './Header.module.scss';
import ss from '../Button/ShopNowButton/ShopNowButton.module.scss';
import c from './LinkList.module.scss';
import Button from '../Button/CTA/Button';
import { LinkList } from './LinkList';
import { ShoppingBag } from './icons/ShoppingBag';
import { usewpdataSelectors } from '../../lib/wpdataState/selectors';
import Topbar from './Topbar/Topbar';
import { MenuBurger } from './icons/MenuBurger';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [visible, setVisible] = useState(true);
  const answerRef = useRef<HTMLUListElement | null>(null);
  const { header, Settings, Layout } = usewpdataSelectors();

  const getHeight = () => {
    return isMenuOpen ? `${answerRef.current?.scrollHeight}px` : '0';
  };

  const scrollThreshold = 500;

  const handleNavigation = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY < 10) {
      setVisible(true);
    } else if (currentScrollY > lastScrollY && currentScrollY > scrollThreshold) {
      setVisible(false);
    } else if (currentScrollY < lastScrollY) {
      setVisible(true);
    }
    setLastScrollY(currentScrollY);
  };

  const handleZendeskClick = () => {
    window.zE.show();
    window.zE.activate();
  };

  useEffect(() => {
    const controlNavbar = () => {
      window.addEventListener('scroll', handleNavigation);
      return () => {
        window.removeEventListener('scroll', handleNavigation);
      };
    };
    controlNavbar();
  }, [lastScrollY]);

  const buttonClasses = `${ss.shopNowButton} ${s.nav__shopNowButton}`;

  return (
    <>
      <Topbar />
      <header className={s.header}>
        <nav
          className={s.nav}
          style={{
            background: header.styles.background,
            borderColor: header.styles.borderColor,
          }}
        >
          <div className={`${s.nav__navWrap} ${s['nav__navWrap--show']}`}>
            <MenuBurger
              isOpen={isMenuOpen}
              className={s.nav__burger}
              handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle navigation"
            />
            <img src={Settings.logo} className={s.nav__productLogo} alt={Settings.brandName} />
            <ul className={`${s.linkListDesktop}`}>
              {header.headerNavigationLinks &&
                header.headerNavigationLinks.map((item, index) => (
                  <li className={`${s.linkList__listItem} ${index === 0 && 'linkList__firstChild'}`} key={index}>
                    {item.link_type === 'custom' ? (
                      item.custom_link.custom_link.includes('zendesk') ? (
                        <button
                          style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                          className={c.listItemText}
                          onClick={() => {
                            handleZendeskClick();
                          }}
                        >
                          <span className={c.listItemText} style={{ color: '#404040' }}>
                            {item.custom_link.tr_custom_link_label}
                          </span>
                        </button>
                      ) : (
                        <a href={item.custom_link.custom_link} className={s.linkList__listLink}>
                          <span className={c.listItemText}>{item.custom_link.tr_custom_link_label}</span>
                        </a>
                      )
                    ) : (
                      <a href={item.page_link.page_link_uri} className={s.linkList__listLink}>
                        <span className={c.listItemText}>{item.page_link.tr_page_link_label}</span>
                      </a>
                    )}
                  </li>
                ))}
            </ul>
            <div className={s.nav__menuIcons}>
              <Button text={header.headerNavRightButtonText} className={buttonClasses} />
              {Layout.hero.layout !== 'lander' && <ShoppingBag />}
            </div>
          </div>
          <ul
            className={`${s.header__navListMobile} ${c.linkList}`}
            style={
              {
                maxHeight: getHeight(),
                '--offset-mobile': !Layout.middleBar.display ? '94px' : '122px',
              } as React.CSSProperties
            }
            ref={answerRef}
          >
            <LinkList />
            <div className={s.burgerMenu_cta_wrapper}>
              <Button text={'SHOP NOW'} className={s.burgerMenu_cta} />
            </div>
          </ul>
        </nav>
      </header>
    </>
  );
};
