import { Modal, ScrollArea } from '@mantine/core';
import { useCartActions } from 'lib/cartState/actions';
import { useCartSelectors } from 'lib/cartState/selectors';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import s from './UpsellModal.module.scss';
import TitleBlock from './TitleBlock';
import ReviewsSwiper from './ReviewsSwiper';
import SwiperGallery from './SwiperGallery';
import Benefits from './Benefits';
import FAQ from './FAQ';
import UserGallery from './UsersGallery';
import { Close } from './icons/Close';
import Timer from 'components/Cart/Summary/Timer';
import { formatPrice } from 'utils/helpers';
import { useMediaQuery } from '@mantine/hooks';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import Button from 'components/Button/CTA/Button';
import { useState } from 'react';

const UpsellModal = () => {
  const [selectedVariants, setSelectedVariants] = useState<{ productId: string; bundleOptionId: string }[]>([]);
  const { UpsellModal: Data } = useCartSelectors();
  const { Theme } = usewpdataSelectors();
  const { pickerUpsells, Products } = useproductsdataSelectors();
  const { setUpsellModalAction, addUpsellToCartAction } = useCartActions();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const UpsellModalData = Data?.product;
  const isUpsellModalOpen = UpsellModalData !== undefined;
  const benefits = UpsellModalData?.upsell_view_modal?.benefits;
  const gallery = UpsellModalData?.upsell_view_modal?.gallery;
  const testimonials = UpsellModalData?.upsell_view_modal?.testimonials;
  const faqs = UpsellModalData?.upsell_view_modal?.faq;
  const userGallery = UpsellModalData?.upsell_view_modal?.swiper_gallery;
  const ctaText = UpsellModalData?.upsell_view_modal?.tr_add_to_cart_button_text ?? 'Add To Cart';
  const upsellType = UpsellModalData?.type;

  const addToCart = () => {
    if (Data?.product?.type === 'bundle') {
      addUpsellToCartAction(Data.addToCartObj.selectedProduct);
    } else {
      addUpsellToCartAction({
        product: UpsellModalData,
        variant: Data?.variant,
      });
    }

    setUpsellModalAction(undefined);
  };

  const calculateBundlePrice = () => {
    const bundleItems = Data.addToCartObj.selectedProduct.bundle;
    const bundleItemsPrice = bundleItems ? bundleItems.reduce((acc, item) => acc + Number(item.price), 0) : 0;
    const productPrice = Data.addToCartObj.selectedProduct.product.price;
    const finalPrice = formatPrice(Number(productPrice) + Number(bundleItemsPrice));
    return finalPrice;
  };

  const setVariant = (productId: string, bundleId: string) => {
    const updatedVariants = selectedVariants.map((variant) =>
      variant.productId === productId ? { ...variant, bundleOptionId: bundleId } : variant
    );

    setSelectedVariants(updatedVariants);
  };
  return (
    <Modal
      opened={isUpsellModalOpen}
      size={'700px'}
      onClose={() => setUpsellModalAction(undefined)}
      styles={{
        body: { padding: '0px' },
        content: { borderRadius: isMobile ? '0px' : '16px' },
        header: { backgroundColor: 'red' },
      }}
      classNames={{ header: s.UpsellModal__header, close: s.UpsellModal__closeBtn }}
      closeButtonProps={{ size: '24px' }}
      withCloseButton={false}
      fullScreen={isMobile}
    >
      {UpsellModalData && (
        <>
          <div className={s.UpsellModal__header} style={{ backgroundColor: Theme?.customButton?.background }}>
            <Close onClick={() => setUpsellModalAction(undefined)} />
          </div>
          <ScrollArea h={isMobile ? 'calc(100vh - 166px)' : 600} type="never" scrollbars="y">
            <div className={s.UpsellModal__content}>
              <TitleBlock />
              {gallery && <SwiperGallery gallery={gallery} />}
              {benefits && <Benefits benefits={benefits} />}
              {testimonials && <ReviewsSwiper testimonials={testimonials} />}
              {faqs && <FAQ faqs={faqs} />}
            </div>
            {userGallery && <UserGallery userGallery={userGallery} />}
          </ScrollArea>

          <div className={s.footer}>
            <Button
              className={`${s.addToCartBtn}`}
              onClickFn={() => {
                addToCart();
              }}
              text={`${ctaText} | ${
                Data?.product?.type === 'bundle' ? calculateBundlePrice() : formatPrice(UpsellModalData.price)
              }`}
            />
            <Timer />
          </div>
        </>
      )}
    </Modal>
  );
};

export default UpsellModal;
