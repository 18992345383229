import React from 'react';
import s from '../QuantityCards/QuantityCards.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartSelectors } from 'lib/cartState/selectors';
import { useCartActions } from 'lib/cartState/actions';

const QuantityIncrementer = () => {
  const { hero } = usewpdataSelectors();
  const { cartProducts } = useCartSelectors();
  const { adjustMainProductQuantityAction } = useCartActions();

  return (
    <div className={s.ctaWrapper__incrementer}>
      <button
        className={s.quantityAdjustButton}
        style={{
          cursor: 'pointer',
          color: Number(cartProducts?.mainProduct?.product?.amount) == 1 ? '#E5E5E5' : hero.styles.textColorPrimary,
        }}
        onClick={() => {
          const qty = Number(cartProducts.mainProduct.product.amount) - 1;
          if (qty === 0) return;
          adjustMainProductQuantityAction(qty);
        }}
      >
        -
      </button>
      <div style={{ color: hero.styles.textColorPrimary }}>
        {cartProducts.mainProduct.product.amount && cartProducts.mainProduct.product.amount}
      </div>
      <button
        className={s.quantityAdjustButton}
        style={{ cursor: 'pointer', color: hero.styles.textColorPrimary }}
        onClick={() => {
          const qty = Number(cartProducts.mainProduct.product.amount) + 1;
          adjustMainProductQuantityAction(qty);
        }}
      >
        {' '}
        +{' '}
      </button>
    </div>
  );
};

export default QuantityIncrementer;
