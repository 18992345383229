import { useEffect, useState } from 'react';
import { Image } from '@mantine/core';
import s from './IgVideos.module.scss';
import VideoModal from 'components/Modals/VideoModal/VideoModal';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type BenefitProps = {
  showMobileDesktop?: boolean;
  showTablet?: boolean;
};

const IgVideos = ({ showMobileDesktop = true, showTablet = true }: BenefitProps) => {
  const [styleClass, setStyleClass] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const { hero } = usewpdataSelectors();

  const handleNext = () => setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % hero.instaBubbles.length);
  const handlePrevious = () =>
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + hero.instaBubbles.length) % hero.instaBubbles.length);

  const openModal = (index: number) => {
    setCurrentVideoIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentVideoIndex(0);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!showMobileDesktop) {
      setStyleClass(s['heroBenefits--showMobileDesktop']);
    }
    if (!showTablet) {
      setStyleClass(s['heroBenefits--showTablet']);
    }
  }, [showMobileDesktop, showTablet]);
  return (
    <section className={`${s.igVideos} ${styleClass}`}>
      <div className={s.igVideos__imageWrapper}>
        {hero.instaBubbles &&
          hero.instaBubbles.map((item, index) => (
            <span key={index} onClick={() => openModal(index)}>
              <Image className={s.igVideos__image} width={64} height={64} src={item.thumbnail_image} alt="alt" />
              <p className={s.igVideos__name}>{item.user_name}</p>
            </span>
          ))}
      </div>
      {hero.instaBubbles && (
        <VideoModal
          isOpen={isModalOpen}
          onClose={closeModal}
          videoIds={hero.instaBubbles}
          videoIndex={currentVideoIndex}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      )}
    </section>
  );
};

export default IgVideos;
