'use client';
import s from './ShoppingBag.module.scss';
import { useCartActions } from '../../../lib/cartState/actions';
import { useCartSelectors } from '../../../lib/cartState/selectors';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import axios from 'axios';

type ShoppingBagProps = {
  className?: string;
};

export const ShoppingBag = ({ className = '' }: ShoppingBagProps) => {
  const { productsQuantity, CartData } = useCartSelectors();
  const { Settings } = usewpdataSelectors();

  const { toggleCartAction, toggleLoaderAction } = useCartActions();
  const classNames = `${className} ${s.shoppingBag}`;

  const handleSubmit = async () => {
    try {
      toggleLoaderAction();
      const url = '/wp-json/brands/cart/v1/session';
      const response = await axios.post(
        url,
        { payload: CartData },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        const urlSymbol = Settings.link.includes('?') ? '&' : '?';
        toggleLoaderAction();
        window.location.href = `${Settings.link}/${urlSymbol}cart=${response.data.session_hash}&aff=${window.btoa(
          window.affiliate
        )}&entryUrl=${window.btoa(window.entry_url)}&postId=remote`;
      }
    } catch (err) {
      console.log(err);
      toggleLoaderAction();
    }
  };

  return (
    <div className={s.shoppingBag__wrapper}>
      <button
        className={classNames}
        onClick={() => {
          Settings.disableCart ? handleSubmit() : toggleCartAction();
        }}
        style={{ background: 'transparent' }}
      >
        <svg
          className={s.shoppingBag__bag}
          width="24"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9369 6.48649V6.16216C14.9369 5.04649 14.5075 3.96973 13.7373 3.17838C12.967 2.38703 11.919 1.94595 10.8331 1.94595C9.74713 1.94595 8.69908 2.38703 7.92883 3.17838C7.15858 3.96973 6.72926 5.04649 6.72926 6.16216V6.48649H14.9369ZM4.8352 12.6486V8.43243H4.31749C3.49673 8.43243 2.81486 9.08108 2.7391 9.92432L1.90571 20.3027C1.82995 21.2368 2.5497 22.0541 3.47147 22.0541H18.1946C19.1164 22.0541 19.8362 21.2368 19.7604 20.3027L18.927 9.92432C18.8513 9.08108 18.1694 8.43243 17.3486 8.43243H16.8309V12.6486C16.8309 13.1805 16.4016 13.6216 15.8839 13.6216C15.3662 13.6216 14.9369 13.1805 14.9369 12.6486V8.43243H6.72926V12.6486C6.72926 13.1805 6.29994 13.6216 5.78223 13.6216C5.26452 13.6216 4.8352 13.1805 4.8352 12.6486ZM4.8352 6.16216C4.8352 4.52757 5.46655 2.95784 6.59036 1.80324C7.71417 0.648649 9.24205 0 10.8331 0C12.4241 0 13.9519 0.648649 15.0758 1.80324C16.1996 2.95784 16.8309 4.52757 16.8309 6.16216V6.48649H17.3486C19.1543 6.48649 20.6569 7.91351 20.8085 9.75568L21.6545 20.1341C21.8186 22.2097 20.2276 24 18.1946 24H3.47147C1.43851 24 -0.1525 22.2097 0.0116522 20.1341L0.857666 9.75568C1.00919 7.91351 2.51181 6.48649 4.31749 6.48649H4.8352V6.16216Z"
            fill="#3A1F15"
          />
        </svg>
        {productsQuantity > 0 && <div className={s.shoppingBag__itemNumber}>{productsQuantity}</div>}
      </button>
    </div>
  );
};
